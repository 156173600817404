import React from "react";
import "../assets/css/App.css";
import { withRouter } from "react-router";
import history from "../history";
import { ReactComponent as Logo } from "../assets/img/DStudios_footer.svg";
import { ReactComponent as FB } from "../assets/img/FB.svg";
import { ReactComponent as Twitter } from "../assets/img/twt.svg";
import { ReactComponent as Insta } from "../assets/img/insta.svg";
import { ReactComponent as Linkedin } from "../assets/img/linkedin.svg";

class footer extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        openMenu: false,
      };
    }
  
    render() {
      return (
        <div className="footer-main" collapseOnSelect expand="lg">
          <div className="footer-sub">
            <div className="footer-logo">
                  <Logo className="footlogo"
                   onClick={() => {
                    history.replace("/");
                  }}
                  width={300}
                  fill="currentcolor"
                  alt="DownloadStudios"/>
            </div>
         
              <div className="footer-right">
                  <div className="footer-social">
                    <div className="social"> <a href="https://www.facebook.com/downloadstudios" target="_blank" rel="noreferrer"> <FB fill="#edfded"/></a></div>
                    <div className="social">  <a href="https://www.instagram.com/download_studios/" target="_blank" rel="noreferrer"><Insta fill="#edfded"/></a></div>
                    <div className="social">  <a href="https://twitter.com/DownloadStudios" target="_blank" rel="noreferrer"><Twitter fill="#edfded"/></a></div>
                    <div className="social"> <a href="https://www.linkedin.com/company/download-studios" target="_blank" rel="noreferrer"> <Linkedin fill="#edfded"/></a></div>
                  </div>

                </div>

          </div>
         
            <div className="footer-copy">
                <div className="footer-content">
                  <p style={{color:"#323232"}}>All rights Reserved | <span style={{color:"#edfded"}}>Download Studios</span> &copy; 2022</p>  
                </div>
                 <div className="Karri">
                        <div className="quickLinks"><a href="/careers">Careers</a></div> 
                        <div className="quickLinks"><a href="/blog">Blog</a></div> 
                        <div className="quickLinks"><a href="/Contact">Contact</a></div> 
                        <div className="quickLinks"><a href="/register">Register</a></div> 

            </div>
          </div>
        </div>
      );
    }
  }
  
  export default withRouter(footer);