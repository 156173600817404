import "../assets/css/App.css";
import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Contact extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      cemail: null,
      cname: null,
      cquery: null
    }
    this.MailHandler = this.MailHandler.bind(this);
    this.isEmailValid = this.isEmailValid.bind(this);
    this.isNameOrQueryValid = this.isNameOrQueryValid.bind(this);
  }
  isNameOrQueryValid(name){

    var isValid = true;

    if(!name){
        isValid = false;
    }

    if(name.length>5000){
        isValid = false;
    }

    return isValid;

}

isEmailValid(email) {
        
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    debugger
    if (!email)
        return false;

    if(email.length>254)
        return false;

    var valid = emailRegex.test(email);
    if(!valid)
        return false;

    var parts = email.split("@");
    if(parts[0].length>64)
        return false;

    var domainParts = parts[1].split(".");
    if(domainParts.some(function(part) { return part.length>63; }))
        return false;

    return true;
}

async MailHandler(event) {

    event.preventDefault()

    let emailVal = this.state.cemail
    let nameVal = this.state.cname
    let queryVal = this.state.cquery


    if(!this.isEmailValid(emailVal)){
        
        toast.error('Please enter valid email', { 
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    
    } 

    else if(!this.isNameOrQueryValid(nameVal)){

        toast.error('Name is invalid', { 
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    }

    else if(!this.isNameOrQueryValid(queryVal)){

        toast.error('Query is invalid or exceeds 5000 characters', { 
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    }

    else{

        emailVal = emailVal.toLowerCase()
        
        var templateParams = {
            mail: this.state.cemail,
            name: this.state.cname,
            message: this.state.cquery
        };

        var request = new XMLHttpRequest();
        request.open('POST','https://download-mailer.herokuapp.com/send-mail')
        request.setRequestHeader('Content-Type', 'application/json')
        request.setRequestHeader('Access-Control-Allow-Origin', '*')
        request.onload = () => {
        
            toast.dark('All set! We will get back to you soon', { 
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }
        request.onerror = (e) => {
          debugger
            console.log(e)
            toast.error('Oops! Could not reach servers.. Try again later', { 
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            
        }
        request.send(JSON.stringify(templateParams))
    
    }
    
}
  render() {
    return (
      <>
        <ToastContainer/>
        <section className="contactPage">
          <Header />
          <div className="contact-main">
            <div className="contact-infomain">
              
              <div className="contact-details">
                <div className="CDM">
                  <div className="contact-detail">
                    <p>Call Us Maybe?</p>
                  </div>
                  <div className="contact-detail">
                    <h1>+91 7899430905</h1>
                  </div>
                </div>
                <div className="CDM">
                  <div className="contact-detail">
                    <p>Introvert?.. Mail us then</p>
                  </div>
                  <div className="contact-detail">
                    <h1>connect@downloadstudios.in</h1>
                  </div>
                </div>
                <div className="CDM">
                  <div className="contact-detail">
                    <p>We should Catch-up</p>
                  </div>
                  <div className="contact-detail">
                    <h3>
                      We’re always moving. Give us a <br />
                      shout,and we'll catch up over a delicious meal.
                    </h3>
                  </div>
                </div>
              </div>
              <div className="contact-row">
                <div className="contact-info">
                  <div className="contact-infosub">
                    <h1>What are you waiting for?</h1>
                  </div>
                  <div className="contactPageCol">
                    <div className="getMessage">
                      <div className="getMessageWarp">
                        <label for="name">Your name</label>
                        <input
                          onChange={async (event) => {
                            this.setState({ cname: event.target.value });
                          }}
                          type="text"
                          name="name"
                        />
                      </div>

                      <div className="getMessageWarp">
                        <label for="mail">Your Email</label>
                        <input
                          onChange={async (event) => {
                            this.setState({ cemail: event.target.value });
                          }}
                          type="email"
                          name="mail"
                        />
                      </div>

                      <div className="getMessageWarp">
                        <label for="message">Your Message</label>
                        <textarea
                          onChange={async (event) => {
                            this.setState({ cquery: event.target.value });
                          }}
                          rows={5}
                          type="text"
                          name="message"
                        />
                      </div>

                      <button onClick={this.MailHandler} type="button">
                        Send message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    );
  }
}
export default Contact;
