import "../assets/css/App.css";
import React, { Component } from "react";
import Header from '../components/Header';
import Footer from '../components/footer';


class refer extends Component {
    render() {
      return (<>
      <section className="referPage">
      <Header/>
      <div className="hero">
          <p style={{fontSize:"small"}} >Refer a Friend</p>
          <h1 style={{fontSize:"xx-large"}}>We're looking for the next gen <br></br> <span style={{color:"#f76336"}}> Artists and Entertainers </span></h1>
          </div>
        \

          

          <div className="details">
          <div className="details-sub">

          <div className="details-text">
            <h1>Refer a future Entertainer to our Festival.</h1>
           {/* <span style={{color:"#f76336"}}> Mowa </span> */}
          
          <p>If you know someone with the potential to create content or entertain people in any way possible, 
            referring them to our fest could maybe accelerate their path to the career of their dreams.</p>
          <p>We Reward the referrals by paying you<span style={{color:"#f76336"}}> 20% </span> of the Registration fee for doing the good deed.</p>

          <p style={{fontSize:"small"}}>Please note: You will receive the payment only if your friend registers for the particular event. <br></br>
You will receive the payment for only those events that come under the referral program.<br></br>
</p>

          </div>
          <div className="details-text">
          <div className="contactPageCol">

                                    <div className="getMessage">

                                        <div className="getMessageWarp">

                                            <label for="name">Your name</label>
                                            <input  onChange={async (event)=>{this.setState({cname: event.target.value})}} type="text" name="name"/>
                                        
                                        </div>
                                        <div className="getMessageWarp">

                                        <label for="name">Referral's name</label>
                                        <input  onChange={async (event)=>{this.setState({cname: event.target.value})}} type="text" name="name"/>

                                        </div>

                                        <div className="getMessageWarp">

                                            <label for="email">Your Instagram username</label>
                                            <input  onChange={async (event)=>{this.setState({cemail: event.target.value})}} type="email" name="email"/>
                                        
                                        </div>
                                         <div className="getMessageWarp">

                                            <label for="email">referral's Mobile</label>
                                            <input  onChange={async (event)=>{this.setState({cemail: event.target.value})}} type="email" name="email"/>
                                        
                                        </div>

                                        {/* <div className="getMessageWarp">

                                            <label for="query">Your Message</label>
                                            <textarea onChange={async (event)=>{this.setState({cquery: event.target.value})}} rows={5} type="text" name="query"/>
                                        
                                        </div> */}

                                        <button onClick={this.MailHandler} type="button">Submit</button>

                                    </div>

                                </div></div>

          </div>
          
          </div>
      <Footer/>
      </section>
      
      </>);
      }
      }
      export default refer;