import "../assets/css/App.css";
import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Ambassador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cemail: null,
      cname: null,
      cmobile: null,
      ccollege: null,
    };
    this.RegisterHandler = this.RegisterHandler.bind(this);
    this.isEmailValid = this.isEmailValid.bind(this);
    this.isMobileValid = this.isMobileValid.bind(this);
    this.isNameOrQueryValid = this.isNameOrQueryValid.bind(this);
  }
  isNameOrQueryValid(name) {
    var isValid = true;

    if (!name) {
      isValid = false;
    }

    if (name.length > 5000) {
      isValid = false;
    }

    return isValid;
  }
  isMobileValid(mobile) {
    var isValid = true;

    if (!mobile) {
      isValid = false;
    }

    if (mobile.length > 12) {
      isValid = false;
    }

    return isValid;
  }

  isEmailValid(email) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) return false;

    if (email.length > 254) return false;

    var valid = emailRegex.test(email);
    if (!valid) return false;

    var parts = email.split("@");
    if (parts[0].length > 64) return false;

    var domainParts = parts[1].split(".");
    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    )
      return false;

    return true;
  }

  async RegisterHandler(event) {
    event.preventDefault();

    let emailVal = this.state.cemail;
    let nameVal = this.state.cname;
    let mobileVal = this.state.cmobile;
    let collegeVal = this.state.ccollege;

    if (!this.isEmailValid(emailVal)) {
      toast.error("Please enter valid email", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!this.isNameOrQueryValid(nameVal)) {
      toast.error("Name is invalid", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!this.isMobileValid(mobileVal)) {
      toast.error("Mobile number is invalid", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!this.isNameOrQueryValid(collegeVal)) {
      toast.error("College name is invalid", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      emailVal = emailVal.toLowerCase();

      var templateParams = {
        email: this.state.cemail,
        name: this.state.cname,
        mobile: this.state.cmobile,
        college: this.state.ccollege
      };

      fetch("https://ambassador-campus.herokuapp.com/add-ambassador", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(templateParams),
      })
      .then((res) => {
        toast.dark("All set! We will get back to you soon", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Oops! Could not reach servers.. Try again later", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });;
    }
  }
  render() {
    return (
      <>
        <ToastContainer />
        <section className="ContactPage">
          <Header />
          <div className="contact-main">
            <div className="contact-infomain">
              <div className="contact-row">
                <div className="contact-details">
                  <div className="CDM">
                    <div className="contact-detail">
                      <p>One step more</p>
                    </div>
                    <div className="contact-detail">
                      <p className="ambassador">
                        Hey there, just fill in your details and register as our
                        Campus Ambassador.
                      </p>
                      <p className="ambassador">
                        We will get back to you soon.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="contact-info">
                  <div className="contact-infosub">
                    <h1>What are you waiting for?</h1>
                  </div>
                  <div className="ContactPageCol">
                    <div className="getMessage">
                      <div className="getMessageWarp">
                        <label for="name">Your name</label>
                        <input
                          onChange={async (event) => {
                            this.setState({ cname: event.target.value });
                          }}
                          type="text"
                          name="name"
                        />
                      </div>

                      <div className="getMessageWarp">
                        <label for="mail">Your Email</label>
                        <input
                          onChange={async (event) => {
                            this.setState({ cemail: event.target.value });
                          }}
                          type="email"
                          name="mail"
                        />
                      </div>

                      <div className="getMessageWarp">
                        <label for="mobile">Your Mobile Number</label>
                        <input
                          onChange={async (event) => {
                            this.setState({ cmobile: event.target.value });
                          }}
                          type="text"
                          name="mobile"
                        />
                      </div>

                      <div className="getMessageWarp">
                        <label for="college">Your University</label>
                        <input
                          onChange={async (event) => {
                            this.setState({ ccollege: event.target.value });
                          }}
                          type="text"
                          name="college"
                        />
                      </div>

                      <button onClick={this.RegisterHandler} type="button">
                        Register
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    );
  }
}
export default Ambassador;
