import "../assets/css/App.css";
import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import history from "../history";
import vistaralogo from "../assets/img/vistara/vistaraL.svg";

class Register extends Component {
  render() {
    return (
      <>
        <section className="registerPage">
          <Header />
          <div className="hero">
            <h1>
              <span style={{ color: "#f76336" }}> VISTARA </span>
            </h1>
            <p>THE SECOND EDITION IS HERE</p>
            <p style={{ fontSize: "small" }}>This time even Better</p>
            <div className="button-group">
              <button
                onClick={() => {
                  history.replace("/register");
                }}
                className="primary"
              >
                Register
              </button>
            </div>
          </div>
          <div className="details">
            <div className="details-sub">
              <img className="imgcard" src={vistaralogo} alt="" />

              <div className="details-text">
                <h1>About Vistara</h1>

                <p>
                  Vistara Festival is an initiative to help Aspirants of
                  entertainment industry to get the spotlight.
                </p>
                <p>
                  This festival is an idea to help and encourage many talents
                  and passionate souls to introduce their works to the world.
                </p>
                <p>
                  To put it in a simple way our goal is to - Find talent,
                  providing them exposure to Entertainment Industry and even
                  build our team
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    );
  }
}
export default Register;
