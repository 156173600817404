import "../assets/css/App.css";
import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import preprod from "../assets/img/storyboard.jpg";
import prod from "../assets/img/productionServ.jpg";
import postprod from "../assets/img/postprod.jpg";

class services extends Component {
  render() {
    return (
      <>
        <section className="ServicePage">
          <Header />
          <div className="hero">
            <h1>There was an
              <span style={{ color: "#f76336" }}>  idea.. </span>
            </h1>
            <p>to bring together a group of remarkable services, </p>
            <p style={{ fontSize: "small" }}>to see if we could provide something more.</p>
          </div>
          <div className="generalContent">
            <div className="generalContentRow">
              <div className="generalContentInfo">
                <div className="generalContentInfoWrap">
                  <h6 className="generalContentTag">Pre production</h6>
                  <p className="generalContentText">
                  Pre-production, is complicated and can be daunting for independent filmmakers,
                  we at Download Studios will be there with for all the requirements starting from- Scripting, Storyboarding, finding crew, location scouting, equipment,budget planning, casting
                  </p>
                </div>
              </div>

              <img className="generalContentImg" src={preprod} alt="Team" />
            </div>

            <div className="generalContentRow">
              <img className="generalContentImg" src={prod} alt="Career" />

              <div className="generalContentInfoRev">
                <div className="generalContentInfoWrap">
                  <h6 className="generalContentTag">Production</h6>
                  
                  <p className="generalContentText">
                  Executive production, maintaining the production calender, set management, call sheet management, crew management, and many more things you can rely on us while you focus only on the output. 
                  </p>
                </div>
              </div>
            </div>

            <div className="generalContentRow">
              <div className="generalContentInfo">
                <div className="generalContentInfoWrap">
                  <h6 className="generalContentTag">Post  production</h6>
                  <p className="generalContentText">
                 You name it, we do it!. Animation, 3D animation, rotoscopy, compositing, matte painting, DI, and other VFX services.       </p>
                </div>
              </div>

              <img className="generalContentImg" src={postprod} alt="Team" />
            </div>
          </div>
          <div className="divider"></div>

          <div className="details">
            <div className="details-sub">
              <div className="details-text">
                <h1 >Why Choose us?</h1>
                <p>
                <b>  Budget-friendly: </b> We have flexible pricing plan designed for independent filmmakers to suit your budget and save money.
                <br></br><br></br>
                <b>  Technical Support : </b>   Technical Support: Our team that works with passion towards the industry will be available to provide support to our clients. You can contact us for suggestions, improvements or any other kinds feedback related to your project.
              <br></br><br></br>
              <b>  High Quality : </b> Our goal is to provide quality work, and help you make great films, so We do not compromise with the quality of the of the services we provide.
                </p>

               
              </div>
            </div>
          </div>
          
          <Footer />
        </section>
      </>
    );
  }
}
export default services;
