import React from "react";
import "../assets/css/App.css";
import { withRouter } from "react-router";
import history from "../history";
import { ReactComponent as Logo } from "../assets/img/DStudios.svg";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openMenu: false,
    };
  }

  render() {
    return (
      <div className="navbar" collapseOnSelect expand="lg">
        <div className="navbar-brand">
          <Logo
            onClick={() => {
              history.replace("/");
              window.location.reload()
            }}
            className="logo"
            width={100}
            fill="currentColor"
            alt="DownloadStudios"
          />
        </div>
            <div className="Nav-father">
        <div className="navbar-items">
          <a className="navbar-item" href="/about">
            About
          </a>
          <a className="navbar-item " href="/Services">
            Services
          </a>
          {/* <a className="navbar-item" href="/contact">
            Careers
          </a> */}
          {/* <a className="navbar-item" href="/contact">
            Blog
          </a> */}
          <a className="navbar-item" href="/contact">
            Contact
          </a>
          <a className="navbar-itemR primary-item" href="/festival">
            Festival
          </a>
          </div>
        </div>

        <div className="menu">
          <div
            onClick={() => this.setState({ openMenu: !this.state.openMenu })}
            className={this.state.openMenu ? "menu-btn open" : "menu-btn"}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div
            className={this.state.openMenu ? "menu-items slideDown" : "hide"}
          >
            <a className="menu-item" href="/about">
              About
            </a>
            <a className="menu-item " href="/Services">
            Services
          </a>
            <a className="menu-item" href="/contact">
              Contact
            </a>
            <a className="menu-item primary-item" href="/festival">
              Festival
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
