import "../assets/css/App.css";
import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
// import about from "../assets/img/about-pic.png";
import vistaralogo from "../assets/img/vistara/vistaraL.svg";
import history from "../history";
import student from "../assets/img/students.jpg";
import filmmaker from "../assets/img/filmmaker.jpg";
import artist from "../assets/img/artist.jpg";
import preprod from "../assets/img/icons.png";
import prod from "../assets/img/productionf.png";
import postprod from "../assets/img/postf.png";

class Home extends Component {
  render() {
    return (
      <>
        <section className="homePage">
          <Header />
          <div className="hero">
            <h1>
              Your Friendly Neighbourhood{""}
              <span style={{ color: "#f76336" }}> Entertainment </span>Studio!!
            </h1>
            <p>Not a Marvel production</p>
            <div className="button-group">
              <button
                onClick={() => {
                  window.open("/campus-ambassador","_blank");
                }}
                className="primary"
              >
                Register as CA
              </button>
            </div>
          </div>
          {/* <div className="generalContent">
            <div className="generalContentRow">
              <div className="generalContentInfo">
                <div className="generalContentInfoWrap">
                  <h3 className="generalContentTagSingle">WHO ARE WE</h3>
                  <p className="generalContentText">
                    We are a world class talent house... <span>in making</span>. Most talened group of entertainment enthusiasts... <span>in near future</span>.
                  </p>
                </div>
              </div>
              <img className="generalContentImg" src={about} alt="Team" />
            </div>
          </div> */}

         

    
          {/* <div className="hero">
            <h1>
              We're redefining
              <span style={{ color: "#f76336" }}> Entertainment </span>Industry
            </h1>
          </div> */}
          <div className="generalContent">

          <div className="generalContentRow">
              <div className="generalContentInfoRev">
                <div className="generalContentInfoWrap">
                  <h6 className="generalContentTag">
                    You can make a difference
                  </h6>
                  <h3 className="generalContentTitle">For Filmmakers</h3>
                  <p className="generalContentText">
                  We are here to help independent filmmakers create films and web series across languages and OTT platforms. We stand with independent films by providing pre-production, production and post-production services for affordable prices.
                   </p>
                </div>
              </div>
              <img className="generalContentImg" src={filmmaker} alt="Career" />
            </div>

          <div className="generalContentRow">
              <img className="generalContentImg" src={artist} alt="Career" />

              <div className="generalContentInfoRev">
                <div className="generalContentInfoWrap">
                  <h6 className="generalContentTag">For Artists</h6>
                  <h3 className="generalContentTitle">
                    A platform you deserve
                  </h3>
                  <p className="generalContentText">
                  This is a place where you get to be who you truly are. 
                  If you think you have the potential to be a significant member of this team, 
                  write to us.

                  </p>
                </div>
              </div>
            </div>

           
            <div className="generalContentRow">
              <div className="generalContentInfo">
                <div className="generalContentInfoWrap">
                  <h6 className="generalContentTag">for passionate Students</h6>
                  <h3 className="generalContentTitle">The Connoisseurs club</h3>
                  <p className="generalContentText">
                  Connoisseurs is one of our driving forces. This forum will be a combination of animation, filmmaking, gaming and cultural enthusiasts, adding their interests, galloping their galore and also providing an imposing platform for individuals to showcase their talents and explore their dreams.
   
                  </p>
                </div>
              </div>

              <img className="generalContentImg" src={student} alt="Team" />
            </div>  

           
          </div>
 <div className="details">
            <div className="details-sub">
              <img className="imgcard" src={vistaralogo} alt="" />
              <div className="details-text">
                <h1>VISTARA</h1>
                <p className="generalContentText">
                Vistara is an initiative to help aspirants of the entertainment industry to get the spotlight.
                This is an idea to help and encourage talented and passionate souls to showcase their skills to the world.

                </p>

                <div className="button-group-sub">
                  <button
                    onClick={() => {
                      history.replace("/register");
                    }}
                    className="primary"
                  >
                    More Details
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="divider"></div>

          <div className="services">
            <div className="serviceRow">
              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Website development" src={preprod} />
                  </div>
                </div>

                <h4 className="serviceTitle">Pre-production</h4>
                <p className="serviceText">
                Scripts, Storyboarding, Locations, Casting , and everything else you can name under Pre-production
                </p>
              </div>

              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Application development" src={prod} />
                  </div>
                </div>

                <h4 className="serviceTitle">Production </h4>
                <p className="serviceText">
                You lack the equipment? or you need a team to finish your Production? or you want to us shoot your film?
                </p>
              </div>

              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Graphic Design" src={postprod} />
                  </div>
                </div>

                <h4 className="serviceTitle">Post Prodcution</h4>
                <p className="serviceText">
                Animation, Compositing, Rotoscopy, 3D Vfx, Matte Painting,DI and everything else you can name under post-production
                
                </p>
              </div>
            </div>

        

            <div className="button-group">
              <button
                onClick={() => {
                  history.replace("/Services");
                }}
                className="primary"
              >
                More Details
              </button>
            </div>
          </div>
          <Footer />
        </section>
      </>
    );
  }
}

export default Home;
