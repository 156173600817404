import "../assets/css/App.css";
import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import history from "../history";
import student from "../assets/img/students.jpg";
import supportive from "../assets/img/support.jpg";
import rollercoaster from "../assets/img/roller-coaster.png";
import adamantium from "../assets/img/claw.png";
import bazinga from "../assets/img/confetti.png";
import ticket from "../assets/img/ticket.png";
import recharge from "../assets/img/rechargeable-battery.png";
import gym from "../assets/img/gym.png";
import mental from "../assets/img/abuse.png";
import perks from "../assets/img/smiley.png";
class careers extends Component {
  render() {
    return (
      <>
        <section className="homePage">
          <Header />
          <div className="hero">
            <h1>
              To the job you'll <span style={{ color: "#f76336" }}>love</span>{" "}
              most!!
            </h1>
            <p>... At Download Studios</p>
            <div className="button-group">
              <button
                onClick={() => {
                  history.replace("/register");
                }}
                className="primary"
              >
                View Jobs
              </button>
            </div>
          </div>
          <div className="generalContent">
            <div className="generalContentRow">
              <div className="generalContentInfo">
                <div className="generalContentInfoWrap">
                  <h6 className="generalContentTag">
                    Supportive like your BFF
                  </h6>
                  <p className="generalContentText">
                    They said only in dreams can Men be truly free… Twas always
                    thus, and always thus will be.. We think we are here to
                    change that, what if I say we are here to encourage you to
                    choose the career path of your dreams.
                  </p>
                </div>
              </div>

              <img className="generalContentImg" src={supportive} alt="Team" />
            </div>
          </div>
          <div className="generalContent">
            <div className="generalContentRow">
            <img className="generalContentImg" src={student} alt="Team" />

              <div className="generalContentInfo">
                <div className="generalContentInfoWrap">
                  <h6 className="generalContentTag">for passionate Students</h6>
                  <h3 className="generalContentTitle">The connoisseurs club</h3>
                  <p className="generalContentText">
                  Connoisseurs is one of our driving forces. This forum will be a combination of animation, filmmaking, gaming and cultural enthusiasts, adding their interests, galloping their galore and also providing an imposing platform for individuals to showcase their talents and explore their dreams.

                  </p>
                </div>
              </div>

            </div>
          </div>
          <div className="about-hero">
            <h1>
              We're<span style={{ color: "#f76336" }}> </span>made up of ..
            </h1>
            <p>Coolness</p>
          </div>
          <div className="services">
            <div className="serviceRow">
              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Website development" src={rollercoaster} />
                  </div>
                </div>

                <h4 className="serviceTitle">That Roller Coaster</h4>
                <p className="serviceText">
                  In order to learn quicker and foster innovation while we leave
                  an impact, we dare to take risks.
                </p>
              </div>

              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Application development" src={adamantium} />
                  </div>
                </div>

                <h4 className="serviceTitle">Bond strong like Adamantium</h4>
                <p className="serviceText">
                  We bond like a family, celebrate your achievements and make
                  sure that bond lasts even after the life at Download Studios{" "}
                </p>
              </div>

              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Graphic Design" src={bazinga} />
                  </div>
                </div>

                <h4 className="serviceTitle">Say Bazinga!</h4>
                <p className="serviceText">
                  We Love having Fun and being mindful, It's like mental peace
                  is born here.
                </p>
              </div>
            </div>
          </div>
          <div className="about-hero">
            <h1>
              You get<span style={{ color: "#f76336" }}> </span>...
            </h1>
            <p>what you fu*king deserve</p>
          </div>
          <div className="services">
            <div className="serviceRow">
              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Website development" src={ticket} />
                  </div>
                </div>

                <h4 className="serviceTitle">Tickets on us</h4>
                <p className="serviceText">
                  Yes be it a Marvel movie or Zakir Khan's Stand Up show if we
                  go together as a team, We Pay for it. and 4 Movies/Stand-up
                  specials per month if you choose to dump us and go alone.{" "}
                </p>
              </div>

              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Application development" src={recharge} />
                  </div>
                </div>

                <h4 className="serviceTitle">Recharge Time</h4>
                <p className="serviceText">
                  Get 30 vacation days per year, To Spend time with family and
                  friends.
                </p>
              </div>

              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Graphic Design" src={mental} />
                  </div>
                </div>

                <h4 className="serviceTitle">Mental Health</h4>
                <p className="serviceText">
                  With a supportive team like us you'll not need a therapist,
                  but just incase if you need one, then we got you covered.
                </p>
              </div>
            </div>

            <div className="serviceRow">
              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Website development" src={gym} />
                  </div>
                </div>

                <h4 className="serviceTitle">GYM bois &amp; gals</h4>
                <p className="serviceText">
                  As our beloved Prime minister said GYM jao body badao. We help
                  you get that dream body. your gym membership fee is reimbursed
                  by us.{" "}
                </p>
              </div>

              <div className="serviceCard">
                <div className="iconWrap">
                  <div className="serviceIcon">
                    <img alt="Application development" src={perks} />
                  </div>
                </div>

                <h4 className="serviceTitle">OTHER Perks</h4>
                <p className="serviceText">
                  Well...few other perks to make your friends in other companies
                  jealous
                </p>
              </div>
            </div>
          </div>
          <div className="hero">
            <h1>
              Let's<span style={{ color: "#f76336" }}> </span>grab the door for
              you ..
            </h1>
            <p>Apply Now</p>
            <p>If you wanna join, then mail us.</p>
          </div>

          {/* <div className="divider"></div> */}

          
          <Footer />
        </section>
      </>
    );
  }
}

export default careers;
