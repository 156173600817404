import "../assets/css/App.css";
import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import yas from "../assets/img/team/yaswanth.jpg";
import akhil from "../assets/img/team/cosanyasi.jpg";
import { ReactComponent as FB } from "../assets/img/FB.svg";
import { ReactComponent as Twitter } from "../assets/img/twt.svg";
import { ReactComponent as Insta } from "../assets/img/insta.svg";
import { ReactComponent as Linkedin } from "../assets/img/linkedin.svg";
import pawan from "../assets/img/team/pawan.jpg";
import nikhil from "../assets/img/team/nikhil.jpg";
import boastLogo from "../assets/img/DStudios.png"

class about extends Component {
  render() {
    return (
      <>
        <section className="aboutPage">
          <Header />
          <div className="hero">
            <h1>
              We'll be there for<span style={{ color: "#f76336" }}> you </span>
              !!
            </h1>
            <p>When the rain starts to pour</p>
          </div>
          <div className="generalContent">
            <div className="generalContentRow">
              <div className="generalContentInfo">
                <div className="generalContentInfoWrap">
                  <h6 className="generalContentTagSingle">About Us</h6>
                  <p className="generalContentText">
                    We are creating a new path , for a generation of Indians who
                    thrive to be part of entertainment industry.
                  </p>
                </div>
              </div>

              <img className="generalContentImg" src={boastLogo} alt="Team" />
            </div>
          </div>

          <div className="about-hero">
            <h1>
              Our Core<span style={{ color: "#f76336" }}> Values </span>
            </h1>
          </div>
          <div className="services">
            <div className="serviceRow">
              <div className="coreCard">
                <div className="iconWrap">
                  {/* <div className="serviceIcon">
                <img alt="Website development" src={about}/>
            </div> */}
                </div>

                <h4 className="serviceTitle">Support</h4>
                <p className="serviceText">
                  Supportive environment is the birth place of creativity, in
                  such places great ideas rise to the top. So supportive that
                  you'll forget your girl
                </p>
              </div>

              <div className="coreCard">
                <div className="iconWrap">
                  {/* <div className="serviceIcon">
                <img alt="Application development" src={about}/>
            </div> */}
                </div>

                <h4 className="serviceTitle">Uplift </h4>
                <p className="serviceText">
                  Let's come together to build a society from the roots up to
                  uplift each other for all the Artists our there
                </p>
              </div>

              <div className="coreCard">
                <h4 className="serviceTitle">Excel</h4>
                <p className="serviceText">
                  We stand for each other to make sure we empower minds and
                  enable them to excel in the field of their dreams and to
                  create a future they deserve.
                </p>
              </div>
            </div>
          </div>
         
          <div className="about-hero">
            <h3>
              Our <span style={{ color: "#f76336" }}> Founders </span>
            </h3>
          </div>

          <div className="founders">
            <div className="card">
              <img src={yas} alt="sanyasi" />
              <div className="data">
                <h2>Yaswanth Srinivas</h2>
                <b>Co-founder and CTO</b>
                <p>Genius,Loyal,Middle Class,Philanthropist - Tony Stark</p>
                <div className="data-social">
                  <a
                    href="https://www.instagram.com/yashu_2806/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Insta />
                  </a>
                  <a
                    href="https://twitter.com/fall_n1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/yashu2806/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Linkedin />
                  </a>
                </div>
              </div>
            </div>
            <div className="card">
              <img src={akhil} alt="cosanyasi" />
              <div className="data">
                <h2>Akhil Ram</h2>
                <b>Co-founder and CEO</b>
                <p>
                  My mom said Self tamte is bad, so I'm not writing anything..
                  All I can say is I'm great and funny.
                </p>
                <div className="data-social">
                  <a
                    href="https://www.facebook.com/akhilramreddi/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <FB />
                  </a>
                  <a
                    href="https://www.instagram.com/cosanyasi/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Insta />
                  </a>
                  <a
                    href="https://twitter.com/cosanyasi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/akhil-ram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <Linkedin />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="team">
            <div className="team-sub">
              <div className="team-text">
                <h1>CORE</h1>
                <p>
                  We are a bunch of wholesome humans each with our own dreams
                  and goals, united by a mission.
                </p>
              </div>
            </div>
          </div>

          <div className="teamMembers">
            <div className="teamMember">
              <div className="card">
                <img src={pawan} alt="sanyasi" />
                <div className="data">
                  <h2>Pawan Patel</h2>
                  <b>COO</b>
                  <p>Fashion icon of company and Single af</p>
                  <div className="data-social">
                    <a
                      href="https://www.instagram.com/mepawanpatel/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Insta />
                    </a>
                    <a
                      href="https://twitter.com/pawanpatell20"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Twitter />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/pwanpatel/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Linkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="teamMember">
              <div className="card">
                <img src={nikhil} alt="sanyasi" />
                <div className="data">
                  <h2>Nikhil Digi</h2>
                  <b>Head of Marketing</b>
                  <p>His silence will annoy the f8ck out of you.</p>
                  <div className="data-social">
                    <a
                      href="https://www.instagram.com/nikhilavan/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Insta />
                    </a>
                    <a
                      href="https://twitter.com/nikhilavan"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Twitter />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/nikhil-digi/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Linkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </section>
      </>
    );
  }
}
export default about;
