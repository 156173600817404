import "../assets/css/App.css";
import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/footer";
import history from "../history";
import vistaralogo from "../assets/img/vistara/vistaraL.svg";
// import Brochure from "../assets/img/PDF/brochure.pdf"

class festival extends Component {
  render() {
    return (
      <>
        <section className="FestivalPage">
          <Header />
          <div className="hero">
            <h1>
              <span style={{ color: "#f76336" }}> VISTARA </span>
            </h1>
            <p>THE SECOND EDITION IS HERE</p>
            <p style={{ fontSize: "small" }}>This time even Better</p>
            <div className="button-group">
              <button
                onClick={() => {
                  history.replace("/register");
                }}
                className="primary"
              >
                Register
              </button>
            </div>
          </div>
          <div className="details">
            <div className="details-sub">
              <img className="imgcard" src={vistaralogo} alt="" />

              <div className="details-text">
                <h1>About Vistara</h1>

                <p>
                  Vistara- Annual cultural festival organized by Download Studios, scheduled from
                  18th April to 20th April 2022 is an initiative to help aspirants of the entertainment industry to get the spotlight.

                </p>
                <p>
                We're looking forward to gathering participants from across India. The fest features various clusters like theatre, music, dance, literature, creatives, fashion, and many others.

                </p>
                <p>
                To put it in a simple way our goal is to - Find talent, provide them exposure to Entertainment Industry and build our team alongside.
                </p>
              </div>
            </div>
          </div>

          <div className="details">
            <div className="details-sub">
              <div className="details-text">
                <h1>Important Dates</h1>

                <p>
                  December 06th{" "}
                  <span style={{ color: "#f76336" }}> Early Bird </span>{" "}
                  Registrations open
                </p>
                <p>
                  January 15th{" "}
                  <span style={{ color: "#f76336" }}> Early Bird </span>{" "}
                  Registrations Closes
                </p>

                <p>
                  January 15th{" "}
                  <span style={{ color: "#f76336" }}> Regular </span>{" "}
                  Registrations Open
                </p>
                <p>
                  February 16th{" "}
                  <span style={{ color: "#f76336" }}> Regular </span>{" "}
                  Registrations Closes
                </p>
              </div>
              <div className="details-text">
                <h1>Announcements </h1>

                <p className="announceCard">
                  {" "}
                  Vistara Festival 2022 Edition Registrations will open Soon.
                </p>

                <p className="announceCard">
                  {" "}
                  Reach out for Collaborations- Event
                  Partner/Hospitality/Influencer{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="about-hero">
            <h1>
              <span style={{ color: "#f76336" }}> Events </span>
            </h1>
            {/* <p>CLUSTERS</p> */}
            <p style={{ fontSize: "small" }}> Coming Soon!</p>
           
          </div>
         
          {/* <div className="button-group">
            <button
              onClick={() => {
                history.replace("/register");
              }}
              className="primary"
            >
              Register
            </button>
          </div> */}

          <Footer />
        </section>
      </>
    );
  }
}
export default festival;
