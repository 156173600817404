import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from './history'
import Home from "./screens/Home.js"
import Contact from"./screens/Contact.js"
import aboutus from "./screens/about"
import vistara from "./screens/festival"
import services from "./screens/services"
import register from "./screens/register"
import refer from "./screens/refer"
import careers from "./screens/careers"
import ambassador from "./screens/Ambassador"
import One from "./screens/one"

class Routes extends React.Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={Home} initial/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/about" component={aboutus}/>
                    <Route exact path="/festival" component={vistara}/>
                    <Route exact path="/Services" component={services}/>
                    <Route exact path="/register" component={register}/>
                    <Route exact path="/refer" component={refer}/>
                    <Route exact path="/careers" component={careers}/>
                    <Route exact path="/campus-ambassador" component={ambassador}/>
                    <Route exact path="/team/akhil" component={One}/>
                    <Route exact path="/team/yaswanth" component={One}/>

                </Switch>
            </Router>
        )
    }
}

export default Routes;